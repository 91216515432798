import React from "react";
import Feature from "@static/images/feature/feature-home-c.png";
import Feature1 from "@static/images/feature/feature-home-c1.png";
import Feature2 from "@static/images/feature/feature-home-c2.png";
import Feature3 from "@static/images/bg/bg-home-b.png";
import Image from "next/image";
import { useWindow } from "@middleware/hooks";
import style from "./HomeHead.module.scss";

export default function SimpleGellery() {
  const { isMobile } = useWindow(false);

  return (
    <>
      {!isMobile ? (
        <Image
          className={style.image}
          alt="feature top"
          src={Feature3}
          quality={100}
          priority
        />
      ) : (
        <Image
          className={style.image}
          alt="feature top"
          src={Feature}
          quality={100}
          priority
        />
      )}
      <Image
        className={style.image}
        alt="feature top"
        src={Feature1}
        quality={100}
        priority
      />
      <Image
        className={style.image}
        alt="feature top"
        src={Feature2}
        quality={100}
        priority
      />
    </>
  );
}
